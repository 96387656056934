import React, { useState } from 'react';
import { TextInput } from '../../../../components/TextInput';

interface Props {
  name: string;
  id: string;
  labeltext: string;
  iconleft: string;
  iconright?: string;
  iconrightchange?: string;
  type?: string;
  typeinputchange?: string;
  errormessage?: string;
  disabled?: boolean;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  mask?: (phone: string) => string;
  iconTitle?: string;
  iconId?: string;
  required?: boolean;
}
export const InputForm = (props: Props) => {
  const {
    onChange,
    name,
    id,
    labeltext,
    iconleft,
    iconright,
    iconrightchange,
    type,
    typeinputchange,
    value,
    errormessage,
    mask,
    onBlur,
    disabled,
    iconTitle,
    iconId,
    required,
  } = props;
  const [icon, setIcon] = useState(iconright);
  const [typeInput, setTypeInput] = useState(type);
  const onClick = () => {
    setIcon(icon === iconright ? iconrightchange : iconright);
    setTypeInput(typeInput === type ? typeinputchange : type);
  };
  return (
    <TextInput
      name={name}
      id={id}
      labeltext={labeltext}
      iconleft={iconleft}
      iconright={icon}
      iconTitle={iconTitle}
      iconId={iconId}
      type={typeInput}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onClick={onClick}
      errorMessage={errormessage}
      mask={mask}
      disabled={disabled}
      required={required}
    />
  );
};
