import React from 'react';
import cx from 'classnames';
import { Container } from '../../../../../../components/Container';
import { Icon } from '../../../../../../components/Icons';
import { Link } from '../../../../../../components/Link';
import styles from './style.module.css';

export const SignUpHeader = () => {
  const url = `${process.env.FTP_IMAGENS}images/register-pj/background-header-register-pj.png`;
  const caHeaderBg = {
    backgroundImage: `url(${url})`,
  };
  const caHeader = cx('cover', styles.caHeader);
  const separateText = cx('br10 bn w-100 ma0', styles.separateText);
  const catTitle = cx('flex flex-column mb72 mb72-m', styles.caTitle);
  const styleLink = cx('pointer', styles.svgr);
  const styleContent = cx('pt4 absolute ml16', styles.styleContent);
  const styleDivLink = cx('mt16 mt32-ns mt40-m mt48-l ml16 ml24-ns ml40-m', styles.divLink);

  return (
    <div className={caHeader} style={caHeaderBg}>
      <Container className="h-100">
        <section className="h-100 absolute">
          <div className={styleDivLink}>
            <Link to="/" className={styleLink}>
              <Icon name="SvgIconLogoAutoscarInline" />
            </Link>
          </div>
          <section className={styleContent}>
            <div className={catTitle}>
              <h1
                className="dn db-ns db-m db-l abbey f32-ns f44-m f44-l fw7 mb16-ns mt0"
                aria-live="assertive"
              >
                Aqui é mais rápido vender veículos.
              </h1>
              <h1 className="dn-ns dn-m dn-l abbey f18 fw7 mb8 mt0" aria-live="assertive">
                Aqui é <br /> mais rápido vender veículos.
              </h1>
              <hr className={separateText} aria-live="assertive" />
              <p className="f14 f20-ns f24-m f24-l fw2 abbey mt4 mb0">
                Cadastre sua loja e venda mais.
              </p>
            </div>
          </section>
        </section>
      </Container>
    </div>
  );
};
