import React from 'react';
import cx from 'classnames';
import { AddImg } from './AddImg';
import styles from './AddPhotos.module.css';

interface Props {
  logoOnDrop: (e: any) => void;
  logoRemove: (e: any) => void;
  coverOnDrop: (e: any) => void;
  coverRemove: (e: any) => void;
  hasImage: boolean;
  hasImageCompany: boolean;
}

const renderImg = ({
  logoOnDrop,
  logoRemove,
  coverOnDrop,
  coverRemove,
  hasImage,
  hasImageCompany,
}: Props) => {
  const handleTitleLogo = () => {
    const sizeText = cx('f14 fw3 abbey ma0 lh-copy', styles.sizeTxt);
    return (
      <>
        <p className={sizeText}>
          Apresente sua empresa para as pessoas. Insira o logo e uma foto da fachada.
        </p>
        <h2 className="f14 abbey fw7 mt24 mb0">Foto de perfil</h2>
        <p className="f12 fw3 abbey ma0 pb4 lh-copy">Insira sua marca no tamanho 800x800px.</p>
      </>
    );
  };

  const handleTitle = () => {
    return (
      <>
        <p className="ma0 f14 abbey fw7 mt24">Foto de capa</p>
        <p className="f12 fw3 abbey ma0 mb4 lh-copy">
          Dica: foto da fachada no tamanho 1920x768px.
        </p>
      </>
    );
  };

  return (
    <div className="mb24 mb48-ns">
      <h1 className="f18 fw6 abbey mt24 mb4 mb0 lh-copy">Adicionar fotos</h1>
      <AddImg
        id="logo_company"
        renderTitle
        title={handleTitleLogo}
        sizeLogo="smallLogo"
        description="o logo"
        onDrop={logoOnDrop}
        remove={logoRemove}
        hasImage={hasImage}
        iconOn="top"
      />
      <div className="dn db-ns pt8-ns" />
      <AddImg
        id="front_company"
        renderTitle
        title={handleTitle}
        sizeLogo="mediumLogo"
        description="a foto da sua empresa"
        onDrop={coverOnDrop}
        remove={coverRemove}
        hasImage={hasImageCompany}
        iconOn="left"
      />
    </div>
  );
};

const AddPhotos = (props: Props) => {
  return <div>{renderImg(props)}</div>;
};

export { AddPhotos };
