import React, { FormEvent, Component } from 'react';
import cx from 'classnames';
import { Form, Field, getIn } from 'formik';
import { orderBy } from 'lodash';
import find from 'lodash/find';
import { AddPhotos } from '../../../../../../components/AddPhotos';
import { Button } from '../../../../../../components/Button';
import { Link } from '../../../../../../components/Link';
import { Select } from '../../../../../../components/Select';
import { DataServiceIbge } from '../../../../../../services/DataServiceIbge';
import {
  cellPhone,
  cnpj as cnpjMask,
  onlyNumbers,
  cep as cepMask,
  removeSpecialCharacters,
} from '../../../../../../utils/mask';
import { validarCNPJ } from '../../../../../../utils/validationForm';
import { InputForm } from '../../../InputForm/InputForm';
import { ValidateExistingEmail } from '../../../SignUp/services/ValidateExistingEmail';

interface Props {
  title: string;
  errors: any;
  values: any;
  touched: any;
  isSubmitting: boolean;
  isLoading?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: () => void;
}

interface SelectType {
  value: number;
  label: string;
}

interface Photo {
  id: string;
  principal: boolean;
  url: string;
}

interface State {
  errorMessage: string;
  cnpjError: string;
  emailError: string;
  ufList: string[];
  cityList: string[];
  hasValidCep: boolean;
}

export class CompanySignUpForm extends Component<Props, State> {
  state: State = {
    errorMessage: '',
    cnpjError: '',
    emailError: '',
    ufList: [],
    cityList: [],
    hasValidCep: false,
  };

  componentDidMount() {
    DataServiceIbge.getUf()
      .then((res: any) => this.setState({ ufList: res }))
      .catch(err => console.error(err));
  }

  onValidateEmail = async (value: string, onBlur: any, errors: any) => {
    await onBlur(value);
    if (!errors) {
      const response = await ValidateExistingEmail.validateEmail(value)
        .then(res => res)
        .catch(error => {
          return error.response;
        });
      if (response && response.status === 409) {
        return this.setState({ emailError: response.data.message });
      }
      return this.setState({ emailError: '' });
    }
  };

  fillCepForms = async (field: any, props: any) => {
    const { default: cep } = await import('cep-promise');
    props.form.setFieldTouched('cep', true);
    cep(field.value)
      .then(async res => {
        const { ufList } = this.state;
        const { city, neighborhood, street } = res;
        const fetchedUf: any = find(ufList, (uf: SelectType) => uf.label === res.state);
        if (fetchedUf) {
          await DataServiceIbge.getCity(fetchedUf.value)
            .then((response: any) => {
              props.form.setFieldValue('uf', null);
              props.form.setFieldValue('city', null);
              const fetchedCity = find(response, (town: SelectType) => town.label === city);
              props.form.setFieldValue('city', fetchedCity);
            })
            .catch(err => console.error(err));
          props.form.setFieldValue('address', street ? street : '');
          props.form.setFieldValue('neighborhood', neighborhood ? neighborhood : '');
          props.form.setFieldValue('uf', fetchedUf);
          this.setState({ hasValidCep: true });
        }
      })
      .catch(() => {
        props.form.setFieldValue('uf', null);
        props.form.setFieldValue('city', null);
        props.form.setFieldValue('address', '');
        props.form.setFieldValue('neighborhood', '');
        this.setState({ hasValidCep: false });
      });
  };

  customInputComponent = ({ divClass, field, errorMessage, ...props }: any) => {
    const { cnpjError, emailError } = this.state;
    const { name } = field;
    let handleBlur = field.onBlur;

    const yupVerification = !!props.form.touched[name] && props.form.errors[name];
    const setErrorMessage = () => {
      if (name === 'cnpj') {
        return yupVerification || cnpjError;
      }

      if (name === 'email') {
        return yupVerification || emailError;
      }

      return yupVerification || errorMessage;
    };

    if (name === 'cnpj') {
      handleBlur = () => {
        props.form.setFieldTouched('cnpj', true);
        this.setState({ cnpjError: '' });
        if (field.value.length === 18) {
          const isValid = validarCNPJ(field.value);
          if (!isValid) {
            this.setState({ cnpjError: 'CNPJ Inexistente.' });
          }
        }
      };
    }

    if (name === 'cep') {
      handleBlur = () => {
        props.form.setFieldValue('uf', null);
        props.form.setFieldValue('city', null);
        this.fillCepForms(field, props).then(() => this.setState({ hasValidCep: true }));
      };
    }

    if (name === 'email') {
      handleBlur = () => {
        props.form.setFieldTouched('email', true);
        this.onValidateEmail(field.value, field.onBlur, props.form.errors[name]);
      };
    }
    const normalizeFieldData = {
      name: field.name,
      onBlur: handleBlur,
      onChange: field.onChange,
      onfocus: field.onFocus,
      errormessage: setErrorMessage(),
    };

    const errorsInput = cx({
      mb16:
        (!props.form.touched[name] &&
          name !== 'password' &&
          name !== 'whatsapp' &&
          name !== 'telephone') ||
        (!props.form.errors[name] && !!props.form.touched[name] && !errorMessage),
      mb8: (!!props.form.touched[name] && props.form.errors[name]) || errorMessage,
      mb4: name === 'password' || name === 'whatsapp' || name === 'telephone',
    });

    return (
      <div className={errorsInput}>
        <InputForm {...props} {...normalizeFieldData} />
      </div>
    );
  };

  customUfComponent = ({ field, ...props }: any) => {
    const { ufList, errorMessage, hasValidCep } = this.state;

    const handleChange = async (uf: SelectType) => {
      props.form.setFieldValue('uf', uf);
      await DataServiceIbge.getCity(uf.value).then((response: any) => {
        this.setState({ errorMessage: '', cityList: response });
      });
    };

    const normalizeFieldData = {
      options: hasValidCep ? [] : orderBy(ufList, ['label'], ['asc']),
      selected: getIn(props.form.values, 'uf'),
      onChange: handleChange,
      errorMessage:
        (!!props.form.touched[field.name] && props.form.errors[field.name]) || errorMessage,
    };

    return (
      <div>
        <Select {...props} {...normalizeFieldData} isFormik />
      </div>
    );
  };

  customCityComponent = ({ field, ...props }: any) => {
    const { errorMessage, cityList } = this.state;

    const handleChange = (city: SelectType) => {
      props.form.setFieldValue('city', city);
    };

    const normalizeFieldData = {
      options: cityList,
      onChange: handleChange,
      selected: getIn(props.form.values, 'city'),
      errorMessage:
        (!!props.form.touched[field.name] && props.form.errors[field.name]) || errorMessage,
    };

    return (
      <div>
        <Select {...props} {...normalizeFieldData} isFormik />
      </div>
    );
  };

  photoHandlerComponent = ({ field, ...props }: any) => {
    const normalizeFieldData = {
      logoOnDrop: (photo: Photo) => props.form.setFieldValue('logo', photo.url),
      logoRemove: () => props.form.setFieldValue('logo', ''),
      coverOnDrop: (photo: Photo) => props.form.setFieldValue('cover', photo.url),
      coverRemove: () => props.form.setFieldValue('cover', ''),
    };

    const hasImage = props.form.values.logo ? true : false;
    const hasImageCompany = props.form.values.cover ? true : false;

    return (
      <AddPhotos {...normalizeFieldData} hasImageCompany={hasImageCompany} hasImage={hasImage} />
    );
  };

  render() {
    const { handleChange, values, title, isSubmitting } = this.props;
    const { hasValidCep } = this.state;
    const {
      cnpj,
      companyName,
      fantasyName,
      cep,
      uf,
      city,
      address,
      complement,
      neighborhood,
      contactName,
      whatsapp,
      telephone,
      email,
      password,
      confirmPassword,
    } = values;

    return (
      <Form>
        <p className="gray fw3 f14 ma0 mt24 mb8">
          Já possui conta?
          <Link to="/login" className="dib pl4 f14 fw7">
            Fazer login.
          </Link>
        </p>
        <p className="gray fw3 f14 ma0 mb24">
          Você é uma pessoa física?
          <Link to="/login?cadastro" className="dib pl4 f14 fw7">
            Clique aqui.
          </Link>
        </p>
        <Field
          name="cnpj"
          id={title + 'cnpj'}
          labeltext="CNPJ*"
          type="text"
          value={cnpj}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
          mask={cnpjMask}
        />
        <Field
          name="companyName"
          id={title + 'razao-social'}
          labeltext="Razão social*"
          type="text"
          value={companyName}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
        />
        <Field
          name="fantasyName"
          id={title + 'nome-fantasia'}
          labeltext="Nome fantasia*"
          type="text"
          value={fantasyName}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
        />
        <div className="flex justify-between">
          <div className="w-50 mr4 mr0-ns mr12-m mr12-l">
            <Field
              name="cep"
              id={title + 'cep'}
              labeltext="CEP"
              type="text"
              value={cep}
              onChange={handleChange}
              component={this.customInputComponent}
              validateOnChange={false}
              mask={cepMask}
            />
          </div>
          <div className="w-50 ml4 ml12-ns">
            <Field
              name="uf"
              id={title + 'uf'}
              title="Estado*"
              value={uf}
              onChange={handleChange}
              component={this.customUfComponent}
              disabled={hasValidCep}
            />
          </div>
        </div>
        <div className="mb16">
          <Field
            name="city"
            id={title + 'cidade'}
            title="Cidade*"
            value={city}
            onChange={handleChange}
            component={this.customCityComponent}
            disabled={!uf || hasValidCep}
          />
        </div>
        <Field
          name="address"
          id={title + 'endereco'}
          labeltext="Endereço*"
          type="text"
          value={address}
          onChange={handleChange}
          component={this.customInputComponent}
        />
        <div className="flex justify-between">
          <div className="w-50 mr4 mr0-ns mr12-m mr12-l">
            <Field
              name="number"
              id={title + 'numero'}
              labeltext="Número*"
              type="text"
              value={values.number}
              onChange={handleChange}
              component={this.customInputComponent}
              mask={onlyNumbers}
            />
          </div>
          <div className="w-50 ml4 ml12-ns">
            <Field
              name="complement"
              id={title + 'complemento'}
              labeltext="Complemento"
              type="text"
              value={complement}
              onChange={handleChange}
              component={this.customInputComponent}
            />
          </div>
        </div>
        <Field
          name="neighborhood"
          id={title + 'bairro'}
          labeltext="Bairro*"
          type="text"
          value={neighborhood}
          onChange={handleChange}
          component={this.customInputComponent}
        />
        <Field
          name="contactName"
          id={title + 'nome-contato'}
          labeltext="Nome do contato*"
          type="text"
          value={contactName}
          onChange={handleChange}
          component={this.customInputComponent}
        />
        <p className="ma4 mt24">Contato para anúncio*</p>
        <p className="fw3 f11 f12-ns boulder ma4 mb12 lh-copy">
          Adicione pelo menos um dos números para anúncio.
        </p>
        <div className="flex flex-column flex-row-ns justify-between">
          <div className="w-100 w-50-ns mr0-ns mr12-m mr12-l">
            <Field
              name="whatsapp"
              id={title + 'whatsapp'}
              labeltext="WhatsApp"
              type="text"
              value={whatsapp}
              onChange={handleChange}
              component={this.customInputComponent}
              mask={cellPhone}
            />
            <p className="db db-ns mt0 mb16 fw3 f11 boulder w-90 w-80-ns w-100-l">
              Anúncios com WhatsApp possuem mais cliques.
            </p>
          </div>
          <div className="w-100 w-50-ns mb12 mt4 mt0-ns mb16-ns mb0-ns ml12-ns">
            <Field
              name="telephone"
              id={title + 'telefone'}
              labeltext="Telefone"
              type="text"
              value={telephone}
              onChange={handleChange}
              component={this.customInputComponent}
              mask={cellPhone}
            />
          </div>
        </div>
        <Field
          name="email"
          id={title + 'email'}
          labeltext="E-mail*"
          type="text"
          value={email}
          mask={removeSpecialCharacters}
          onChange={handleChange}
          component={this.customInputComponent}
        />
        <Field
          name="password"
          id={title + 'senha'}
          labeltext="Senha*"
          iconright="SvgIconShowMe"
          iconrightchange="SvgIconHideMe"
          type="password"
          value={password}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
        />
        <p className="mt0 fw3 f11 mb16 boulder w-90 w-80-ns w-100-l">
          Crie uma senha com no mínimo 6 caracteres e utilize letras e números.
        </p>
        <Field
          name="confirmPassword"
          id={title + 'confirmacao-senha'}
          labeltext="Confirmar senha*"
          iconright="SvgIconShowMe"
          iconrightchange="SvgIconHideMe"
          type="password"
          value={confirmPassword}
          onChange={handleChange}
          component={this.customInputComponent}
          validateOnChange={false}
        />
        <Field name="photos" component={this.photoHandlerComponent} />
        <div className="mv16">
          <Button type="submit" loading={isSubmitting}>
            Criar conta
          </Button>
        </div>
      </Form>
    );
  }
}
