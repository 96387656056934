import { axiosInstance } from '../../../../../services/axios-instance';

export const ValidateExistingEmail = {
  validateEmail: async (email: string) => {
    try {
      const response = await axiosInstance.post(
        '/user/validateExistingEmail',
        { email },
        { headers: { 'Content-Type': 'application/json' } },
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  },
};
