import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Measure from 'react-measure';
import { Icon } from '../Icons';
import styles from './Section.module.css';

interface Props {
  title?: string;
  classSection?: string;
  lineColor?: string;
  icon?: boolean;
  backgroundMain?: string;
  decorativeImage?: string;
  hasError?: boolean;
  adjustHeight?: number;
}

export const Section = ({
  title = '',
  classSection,
  lineColor,
  icon,
  backgroundMain,
  decorativeImage,
  hasError,
  adjustHeight,
}: Props) => {
  const [initialHeight, setInitialHeight] = useState(0);
  const [sectionHeight, setSectionHeight] = useState(0);

  useEffect(() => {
    setSectionHeight(adjustHeight ? adjustHeight + initialHeight : initialHeight);
  }, [adjustHeight]);

  const heading = cx('f26 f32-l fw2 mb8 mb12-l mt8 mt12-l', classSection);

  const form = cx({
    [styles.formDivWhite]: backgroundMain === 'white',
    [styles.formDivRed]: backgroundMain === 'red',
  });

  const line = cx({
    'gradient-torch-red br10': lineColor === 'redLine',
    'bg-white br10': lineColor === 'whiteLine',
    [styles.lineRed]: lineColor === 'redLine',
    [styles.lineWhite]: lineColor === 'whiteLine',
  });

  const mainBackground = cx(styles.distance, 'pl24-m flex w-100 relative', {
    'bg-white': backgroundMain === 'white',
    [styles.gradient_torch_red_m]: backgroundMain === 'red',
    [styles.backgroundSizeRed]: !hasError && backgroundMain === 'red',
    [styles.backgroundSize]: !hasError && backgroundMain === 'white',
    [styles.backgroundSizeWithError]: hasError,
  });

  const container = cx('pl32 pl40-l', {
    [styles.spacePaddingCar]: backgroundMain === 'white',
    [styles.spacePaddingPhone]: backgroundMain === 'red',
  });

  const renderIcons = () => {
    const iconName = icon ? 'SvgIconGraphic' : 'SvgIconMoney';
    return <Icon name={iconName} />;
  };

  const imgBackgroundPhone = cx('absolute top-0', styles.backgroundImagePhone);
  const imgBackgrounCar = cx('absolute', styles.backgroundImageCar);

  const renderImg = () => {
    if (decorativeImage === 'phone') {
      return (
        <img
          className={imgBackgroundPhone}
          src={`${process.env.FTP_IMAGENS}images/cadastro/mockup-telefone-02.png`}
          alt="imagem de um Telefone"
        />
      );
    } else if (decorativeImage === 'car') {
      return (
        <img
          className={imgBackgrounCar}
          src={`${process.env.FTP_IMAGENS}images/cadastro/Carro_PJ.png`}
          alt="imagem de um carro"
        />
      );
    }
  };

  const display = cx('dn db-m db-l ', styles.displayMain, {
    [styles.displayMainRed]: backgroundMain === 'red',
  });
  const onFormResize = (event: any) => {
    if (initialHeight === 0) {
      setInitialHeight(event.bounds.height);
    }
  };

  const sectionComponent = ({ measureRef }: any) => (
    <div
      ref={measureRef}
      className={mainBackground}
      // tslint:disable-next-line: jsx-no-multiline-js
      style={
        sectionHeight && sectionHeight !== 0
          ? { height: sectionHeight, transition: 'height 0.5s ease' }
          : { transition: 'height 0.5s ease' }
      }
    >
      <div className={form} />
      <div className={display}>
        <div className={container}>
          {renderIcons()}
          <h2 className={heading} dangerouslySetInnerHTML={{ __html: title }} />
          <div className={line} />
          <div>{renderImg()}</div>
        </div>
      </div>
    </div>
  );

  return (
    <Measure bounds onResize={onFormResize}>
      {sectionComponent}
    </Measure>
  );
};
