import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import map from 'lodash/map';
import { ImgUploader } from '../ImageUploader/imglUploader';
import { PhotoCard } from '../PhotoCard';
import styles from './AddPhotos.module.css';

interface Props {
  id: string;
  title: () => void;
  sizeLogo: string;
  description?: string;
  onDrop: (e: any) => void;
  remove: (e: any) => void;
  hasImage: boolean;
  iconOn?: string;
  defaultImage?: string;
  renderTitle?: boolean;
  resetTrigger?: boolean;
  setReseted?: Dispatch<SetStateAction<boolean>>;
}

export const AddImg = ({
  id,
  title,
  renderTitle,
  sizeLogo,
  description,
  remove,
  onDrop,
  hasImage,
  defaultImage,
  iconOn = 'top',
  resetTrigger,
  setReseted,
}: Props) => {
  const [item, setItem] = useState({ url: '' });

  useEffect(() => {
    if (defaultImage && defaultImage !== '') {
      setItem({
        url: defaultImage,
      });
    }
  }, [defaultImage]);

  useEffect(() => {
    if (resetTrigger && defaultImage && defaultImage !== '') {
      setItem({
        url: defaultImage,
      });
      if (setReseted) {
        setReseted(false);
      }
    }
  }, [resetTrigger]);

  const handleDrop = (files: any) => {
    map(files, (picture: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(picture);
      reader.onload = (event: any) => {
        Object.assign(picture, { preview: URL.createObjectURL(picture) });
        const copyItem = { url: event.target.result, id, principal: true };
        onDrop(copyItem);
        setItem(copyItem);
      };
    });
  };

  const handleTitle = () => {
    if (renderTitle) {
      return title();
    }
  };

  const handleRemove = () => {
    const copyItem = { url: '', id, principal: true };
    remove(copyItem);
    setItem(copyItem);
  };

  const handleLogo = () => {
    if (item.url && hasImage) {
      return (
        <div className={styles.sizeImg} key={id + description}>
          <PhotoCard
            principal
            id={id}
            item={item}
            remove={handleRemove}
            onDrop={handleDrop}
            hasChange
          />
        </div>
      );
    }
    return (
      <ImgUploader
        onDrop={handleDrop}
        sizeLogo={sizeLogo}
        description={description}
        iconOn={iconOn}
      />
    );
  };

  return (
    <>
      {handleTitle()}
      {handleLogo()}
    </>
  );
};
