import React, { Component } from 'react';
import { Section } from '../../../../components/Section';
import { Footer } from '../../../Footer';
import { SignUpForm } from './components/SignUpForm';
import { SignUpHeader } from './components/SignUpHeader';
import styles from './style.module.css';

export class CompanySignUp extends Component {
  state = {
    sectionHeightChange: 0,
  };

  getNewSectionHeight = (height: number) => {
    this.setState({ sectionHeightChange: height });
  };

  render() {
    const { sectionHeightChange } = this.state;
    return (
      <div className="relative">
        <div>
          <SignUpHeader />
          <div className="dn db-m db-l">
            <Section
              classSection="abbey"
              title="Cerca de <span class='fw7'>5 mil <br/> buscas</span> todos <br/>os dias."
              lineColor="redLine"
              icon
              backgroundMain="white"
              decorativeImage="car"
            />
            <Section
              classSection="white"
              title="O <span class='fw7'>destaque<br/> ideal</span>
              pra sua loja<br/>
              <span class='fw7'>vender mais.</span><br/>Anunciou,vendeu!"
              lineColor="whiteLine"
              icon={false}
              backgroundMain="red"
              decorativeImage="phone"
              adjustHeight={sectionHeightChange}
            />
          </div>
        </div>
        <SignUpForm
          name="Nome"
          title="Criar conta Loja"
          updateSectionHeight={this.getNewSectionHeight}
        />
        <div className={styles.wrapperFooter}>
          <Footer />
        </div>
      </div>
    );
  }
}
