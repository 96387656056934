import get from 'lodash/get';
import { axiosInstance } from '../../../../../services/axios-instance';
import {
  removePhoneMask,
  removeCnpjMask,
  removeCepMask,
  startWithZero,
} from '../../../../../utils/mask';
import { Values } from '../components/SignUpForm';

export const User = {
  create: ({
    contactName,
    telephone,
    email,
    password,
    cnpj,
    uf,
    city,
    cep,
    complement,
    address,
    number: addressNumber,
    fantasyName,
    companyName,
    logo,
    cover,
    neighborhood,
    whatsapp,
  }: Values) => {
    const payload = {
      name: contactName,
      phone: startWithZero(removePhoneMask(telephone)),
      email,
      password,
      type: 2,
      cpfCnpj: removeCnpjMask(cnpj),
      state: get(uf, 'label', ''),
      city: get(city, 'label', ''),
      cep: removeCepMask(cep),
      additionalAddressData: complement,
      address,
      addressNumber,
      fantasyName,
      companyName,
      companyLogo: logo,
      frontagePhoto: cover,
      addressDistrict: neighborhood,
      whatsapp: startWithZero(removePhoneMask(whatsapp)),
    };
    return axiosInstance.post('/user', payload, {
      headers: { 'Content-Type': 'application/json' },
    });
  },
};
