import React from 'react';
import { Layout } from '../components/Layout';
import { CompanySignUp } from '../modules/Login/components/CompanySignUp';

const CompanySignUpPage = () => (
  <Layout>
    <CompanySignUp />
  </Layout>
);

export default CompanySignUpPage;
