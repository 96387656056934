import React from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { getIcon } from '../../../src/utils/getImgUploaderIcon';
import { Icon } from '../Icons';
import styles from './style.module.css';
import { Text } from './textSmall';

interface Props {
  onDrop: (acceptFiles: any) => void;
  sizeLogo: string;
  description?: string;
  iconOn: string;
}

export const ImgUploader = ({ onDrop, sizeLogo, description, iconOn }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

  const rootClass = cx(
    styles.imageUploader,
    'flex items-center justify-center pa16 w-100',
    {
      [styles.imgSmall]: sizeLogo === 'smallLogo',
      [styles.imgMedium]: sizeLogo === 'mediumLogo',
    },
    'br2 bg-white-90 pointer bw1 b--dashed',
  );

  const container = cx({
    [styles.containerSVG]: sizeLogo === 'smallLogo',
    [styles.containerSvgMedium]: sizeLogo === 'mediumLogo',
  });
  const containerAll = cx('flex', {
    'flex-column items-center': iconOn === 'top',
    'items-center': iconOn === 'left',
    [styles.subMain]: sizeLogo === 'smallLogo',
  });

  const iconName = getIcon(sizeLogo);

  return (
    <>
      <div className={rootClass} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={containerAll}>
          <div className={container}>
            <Icon name={iconName} />
          </div>
          <Text isDragActive={isDragActive} description={description} iconOn={iconOn} />
        </div>
      </div>
    </>
  );
};
