export const getIcon = (sizeLogo: string) => {
  switch (sizeLogo) {
    case 'smallLogo':
      return 'SvgIconCoverPhoto';
    case 'mediumLogo':
      return 'SvgIconHome';
    case 'profileLogo':
      return 'SvgIconProfilePhoto';
    case 'coverPhoto':
      return 'SvgIconCoverPhoto';
  }
  return '';
};
