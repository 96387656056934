import React from 'react';
import cx from 'classnames';
import styles from './style.module.css';

interface Props {
  isDragActive: boolean;
  description?: string;
  iconOn?: string;
}

export const Text = ({ isDragActive, description, iconOn }: Props) => {
  const paragraphClass = 'ma0 fw3 f16';
  const cxDesktopText = cx(
    styles.desktopText,
    paragraphClass,
    {
      mt4: iconOn === 'top',
      mt0: iconOn === 'left',
      [styles.smallText]: iconOn === 'top',
    },
    'dn db-m db-l tc',
  );

  const cxMobileText = cx(
    styles.mobileText,
    paragraphClass,
    {
      mt4: iconOn === 'top',
      mt0: iconOn === 'left',
    },
    'db dn-m dn-l tc ph8',
  );

  const renderDesktopText = () => {
    return isDragActive ? (
      <p className="mt4">
        <span className="b">Solte</span> as fotos para enviar
      </p>
    ) : (
      <p className={cxDesktopText}>
        <span className="fw7">Arraste</span> {description} ou
        <span className="b red"> clique aqui</span>
      </p>
    );
  };

  const space = cx({ pl8: iconOn === 'left' });

  return (
    <div className={space}>
      <p className={cxMobileText}>
        <span className="red fw6">Toque aqui</span> para adicionar {description}.
      </p>
      {renderDesktopText()}
    </div>
  );
};
