export const cellPhone = (phone: string) => {
  if (!phone) {
    return '';
  }
  return phone
    .replace(/\D/g, '')
    .replace(/^(\d{11})(\d+)/g, '$1')
    .replace(/^0/, '')
    .replace(/^(\d{2})(\d+)$/, '$1 $2')
    .replace(/^(\d{2})\s(\d{4})(\d{4})$/, '($1) $2-$3')
    .replace(/^(\d{2})\s(\9?\d{4})(\d{4})$/, '($1) $2-$3')
    .replace(/^(\d{2})(.+)$/, '($1)$2');
};

export const dateMask = (date: string) =>
  (date ?? '')
    .replace(/\D/g, '')
    .replace(/[^0-9]/g, '')
    .replace(/(\d{2})(\d+)/, '$1/$2')
    .replace(/(\d{2})(\d+)/, '$1/$2');

export const cnpj = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{14})(\d+)/g, '$1')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{2})(\d{1,2})$/, '$1-$2');
};

export const cep = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{8})(\d+)/g, '$1')
    .replace(/(\d{5})(\d)/, '$1-$2');
};

export const plateMask = (value: string) => {
  if (!value) {
    return '';
  }
  value = value.toUpperCase();
  return value.replace(/(\w{3})(\d)/, '$1-$2');
};

export const time = (value: string) => {
  if (!value) {
    return '';
  }

  return value
    .replace(/\D/g, '')
    .replace(/^(\d{4})(\d+)/g, '$1')
    .replace(/(\w{2})(\d)/, '$1:$2');
};

export const removeSpace = (v: string) => {
  v = removeSpecialCharacters(v);

  return v.replace(/\s+/g, '').replace('@', '');
};

export const onlyLetters = (v: string) => {
  return v.replace(/[0-9]/, '');
};

export const onlyNumbers = (v: string) => {
  return v.replace(/[^0-9]/, '');
};

export const removeSpecialCharacters = (email: string) => {
  if (!email) {
    return '';
  }
  return email.replace(/[^a-zA-Z0-9./_@-]/g, '');
};

export const removePhoneMask = (phone: string) => {
  return phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
};

export const removeCnpjMask = (value: string) => {
  return value.replace(' ', '').replace('.', '').replace('.', '').replace('/', '').replace('-', '');
};

export const removeCepMask = (value: string) => {
  return value.replace('-', '');
};

export const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};

export const numberWithComma = (valor: any) => {
  if (Number.isInteger(valor)) {
    valor = parseFloat(valor).toFixed(2);
  }

  if (isFloat(valor)) {
    valor = valor.toFixed(2);
  }

  valor = valor.replace(/\D/g, '');
  if (!valor) {
    return null;
  }

  valor = parseInt(valor.replace(/[\D]+/g, ''), 10);
  valor = String(valor);
  valor = valor.replace(/([0-9]{2})$/g, ',$1');

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }
  return valor;
};

export const formatKm = (valor: any) => {
  valor = valor.replace(/\D/g, '');

  if (!valor) {
    return null;
  }
  valor = valor.replace('.', '').replace(',', '');
  valor = Number(valor).toLocaleString('pt-BR');
  return valor;
};

export const onlyLettersAndNumbers = (v: string) => {
  return v.replace(/[^A-Za-z0-9]+/, '');
};

export const money = (value: string) => {
  let v = value;
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{1,2})$/, ',$1');
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  v = v !== '' ? 'R$ ' + v : '';
  return v;
};

export const removeMaskMoney = (value: string) => {
  if (!value) {
    return null;
  }
  if (typeof value !== 'string') {
    return value;
  }

  value = value.replace('R$', '');
  value = value.replace('.', '');
  value = value.replace(',', '.');
  return parseFloat(value);
};

export const thousands = (value: any) => value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export function startWithZero(phone: string) {
  if (!phone) {
    return '';
  }
  return phone[0] === '0' ? phone : '0' + phone;
}

export function preventTrunc(phoneNumber: string) {
  return phoneNumber[0] === '0' ? phoneNumber.substring(1) : phoneNumber;
}
