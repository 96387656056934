import React, { Component } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import Measure from 'react-measure';
import { Container } from '../../../../../../components/Container';
import { ModalSuccessAndError } from '../../../../../../components/ModalSuccessAndError';
import { validationFormCompanySignUp } from '../../../../../../utils/validationForm';
import { TitleForm } from '../../../TitleForm';
import { User } from '../../services/User';
import { CompanySignUpForm } from './CompanySignUpForm';
import styles from './style.module.css';

interface Props {
  name: string;
  title: string;
  changeSectionSizeByError?: any;
  updateSectionHeight: (height: number) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface Values {
  address: string;
  cep: string;
  city: Select | string;
  cnpj: string;
  companyName: string;
  complement: string;
  confirmPassword: string;
  contactName: string;
  cover: string;
  email: string;
  fantasyName: string;
  logo: string;
  neighborhood: string;
  number: string;
  password: string;
  telephone: string;
  uf: Select | string;
  whatsapp: string;
}

interface Select {
  value: number;
  label: string;
}

interface State {
  isModalOpen: boolean;
  modalData: {
    status: boolean;
    modalTitle: string;
    subTitle: string;
    text: string;
    textBtnClose: string;
    actionBtn?: any;
  };
  formHeightChange: number;
  isLoading: boolean;
}

export class SignUpForm extends Component<Props, State> {
  state = {
    isModalOpen: false,
    modalData: {
      status: false,
      modalTitle: '',
      subTitle: '',
      text: '',
      textBtnClose: '',
      actionBtn: () => null,
    },
    formHeightChange: 0,
    isLoading: false,
  };

  handleModalStatus = () => {
    this.setState({ isModalOpen: false });
  };

  renderModal = (success: boolean) => {
    if (success) {
      return this.setState({
        isModalOpen: true,
        modalData: {
          status: true,
          modalTitle: 'Parabéns',
          subTitle: 'Conta criada com sucesso',
          text: 'Seja bem-vindo e bons negócios!',
          textBtnClose: 'Anunciar',
          actionBtn: () => navigate('/login'),
        },
      });
    }
    return this.setState({
      isModalOpen: true,
      modalData: {
        status: false,
        modalTitle: 'Ops!',
        subTitle: 'Erro ao criar sua conta',
        text: 'Tente novamente mais tarde!',
        textBtnClose: 'Voltar',
      },
    });
  };

  handleSubmit = async (values: Values, actions: any) => {
    try {
      const response = await User.create(values);
      if (response.status === 200 || response.status === 201) {
        actions.resetForm();
        return this.renderModal(true);
      }
    } catch (e) {
      await actions.setSubmitting(false);
      return this.renderModal(false);
    }
    await actions.setSubmitting(false);
  };

  onFormResize = (event: any) => {
    const height = event.bounds.height;
    const { formHeightChange } = this.state;

    if (formHeightChange === 0) {
      this.setState({ formHeightChange: height });
    } else {
      this.props.updateSectionHeight(height - formHeightChange);
    }
  };

  render() {
    const initialValues = {
      cnpj: '',
      companyName: '',
      fantasyName: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      contactName: '',
      whatsapp: '',
      telephone: '',
      email: '',
      password: '',
      logo: '',
      cover: '',
      confirmPassword: '',
    };

    const { title, onClick } = this.props;
    const {
      isLoading,
      isModalOpen,
      modalData: { status, modalTitle, subTitle, text, textBtnClose, actionBtn },
    } = this.state;
    const styleDivMeasure = cx(
      'absolute-m absolute-l ml0 bg-white br10 ml24-m pb40 pb48-ns pb24-m pb24-l pb40-ns ph16 pt48 pt40-ns pt0-m pt0-l',
      styles.formContainer,
    );

    const styleDivGeneralWrapper = cx('w-100 w-50-ns w-100-m w-100-l center', styles.wrapperDiv);

    return (
      <Container className={styles.enforceWidth}>
        <Measure bounds onResize={this.onFormResize}>
          {({ measureRef }: any) => (
            <div ref={measureRef} className={styleDivMeasure}>
              <div className={styleDivGeneralWrapper}>
                <TitleForm title={title} className="mt8 mt16-ns mt40-m mt40-l" />
                <Formik
                  initialValues={initialValues}
                  onSubmit={this.handleSubmit}
                  validationSchema={validationFormCompanySignUp}
                  // tslint:disable-next-line: jsx-no-lambda jsx-no-multiline-js
                  render={props => (
                    <CompanySignUpForm
                      onClick={() => onClick}
                      isLoading={isLoading}
                      {...props}
                      title={title}
                    />
                  )}
                />
              </div>
              <ModalSuccessAndError
                success={status}
                title={modalTitle}
                subTitle={subTitle}
                text={text}
                isOpen={isModalOpen}
                closeModal={this.handleModalStatus}
                textBtnClose={textBtnClose}
                actionBtn={actionBtn}
              />
            </div>
          )}
        </Measure>
      </Container>
    );
  }
}
