import React, { Component } from 'react';
import cx from 'classnames';
import styles from './TitleForm.module.css';

interface Props {
  title: string;
  className?: string;
  focusTime?: number;
}

export class TitleForm extends Component<Props> {
  title: React.RefObject<HTMLHeadingElement>;
  constructor(props: Props) {
    super(props);
    this.title = React.createRef();
  }

  componentDidMount() {
    if (this.title && this.title.current) {
      const {
        title: { current },
      } = this;
      const { focusTime } = this.props;
      if (focusTime) {
        setTimeout(() => {
          current.focus();
        }, focusTime);
      } else {
        current.focus();
      }
      setTimeout(() => {
        current.blur();
      }, 500 + (focusTime || 0));
    }
  }

  render() {
    const { title, className } = this.props;
    const titleStyle = cx(
      'trueno fw3 mb12 f26 f30-m f30-l abbey lh-solid mt0 mt32-m mt32-l',
      className,
    );
    const barStyle = cx('gradient-torch-red br10 mb12', styles.bgBarTitle);
    return (
      <>
        <h1 className={titleStyle} tabIndex={0} ref={this.title}>
          {title}
        </h1>
        <div className={barStyle} />
      </>
    );
  }
}
